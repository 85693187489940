import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs';

export const rightsGuard: CanActivateFn = (route, state) => {
  let authService = inject(AuthService);
  // authService.getCookie().subscribe((user)=>{
  //   return user.role !== 'Пользователь';
  // });
  return authService.getCookie().pipe(
    map(user => user.role !== 'Пользователь') // Map the user role to a boolean
  );
};
