import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const loginGuard: CanActivateFn = (route, state) => {
  let authService = inject(AuthService);
  let router = inject(Router);
  const decision = authService.isAuthenticated();
  if(decision){
    return true;
  }
  else{
    // console.log(route.url[0].path);
    let url = `/${route.url[0].path}`;
    if(route.url[1] && route.url[1].path){
      url += `/${route.url[1].path}`;
    }
    localStorage.setItem('url', url);
    confirm('Страница недоступна для неавторизованных пользователей');
    router.navigate(['/login']);
    return false;
  }
};
