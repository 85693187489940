@if(mobile){
  <meta name="viewport" content="width=device-width, initial-scale=0.1" />
} @else {
  <meta name="viewport" content="width=device-width, initial-scale=1" />
}
@if(router.url==='/login' || router.url==='/register'){
<app-shell-login>
  <router-outlet></router-outlet>
</app-shell-login>
}@else if(router.url === '/'){
}@else
{
  <app-shell>
    <router-outlet></router-outlet>
  </app-shell>
}
