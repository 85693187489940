import { Component } from '@angular/core';
import { ApiService } from '../services/api.service';
import { User } from '../interfaces/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FormsModule } from '@angular/forms';
import { DatePipe, NgClass } from '@angular/common';
@Component({
  selector: 'app-client',
  standalone: true,
  imports: [FormsModule, NgClass, DatePipe],
  templateUrl: './client.component.html',
  styleUrl: './client.component.scss',
})
export class ClientComponent {
  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    this.changeRole = '';
    apiService
      .getUser(activatedRoute.snapshot.params['id'])
      .subscribe((data: User) => {
        console.log(data);
        this.user = data;
        this.loading = false;
        this.changeRole = this.user!.role;
        if(this.user.verified){
          this.verification = 'Подтверждён';
        }else{
          this.verification = 'Неподтверждён';
        }
      });
      authService.getCookie().subscribe((user)=>{
        this.role = user.role;
        if(this.role === 'Владелец' || this.role === 'Админ'){
          this.roleCheck = true;
        }
      });
  }
  verification: string = "";
  roleCheck: boolean = false;
  loading: boolean = true;
  user: User | undefined;
  role!: string;  //ХУЙ ЕГО ЗНАЕТ
  changeRole: string;
  
  verifyUser() {
    if(this.verification === 'Подтверждён'){
      this.user!.verified = true;
    }else{
      this.user!.verified = false;
    }
    if (
      this.user!.role !== 'Админ' &&
      (this.role === 'Админ' || this.role === 'Владелец')
    ) {
      this.user!.role = this.changeRole;
    }
    if (this.user) {
      this.apiService
        .updateUser(this.activatedRoute.snapshot.params['id'], this.user)
        .subscribe(() => {
          this.router.navigate(['/clients']);
        });
    } else {
      alert('Неожиданная ошибка');
      this.router.navigate(['/clients']);
    }
  }

  deleteUser(){
    const id = this.activatedRoute.snapshot.params['id'];
    if(confirm("Точно удалить пользователя?")){
      if(id){
        this.apiService.deleteUser(id).subscribe((data)=>{
          if(data && '_id'){
            this.authService.setCookieDeletedUser(data);
          }
          this.router.navigate(['/clients']);
        });
      } 
    }
  }

  checkOld(date: Date | undefined){
    if(!date){
      return false;
    }
    const ans = new Date().getTime() - new Date(date).getTime();
    return ans/1000/60/60/24/30 >= 3;
  }
}
