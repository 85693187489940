import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor() { }

  MAP_TO_RUS: { [key: string]: string } = {
    'typeF': 'Тип фасада',
    'thickness': 'Толщина',
    'freza': 'Обкатная фреза',
    'milling': 'Фрезеровка',
    'ispol': 'Вид фасада',
    'decor': 'Декор',
    'texture': 'Текстура',
    'star': 'Старение',
    'polish': 'Лак',
    'collect': 'Коллекция',
    'kromka': 'Кромка',
    'kromkaDesign': 'Дизайн кромки',
    'height': 'Высота',
    'width': 'Ширина',
    'amount': 'Кол-во',
    'area': 'Sдет',
    'totalArea': 'Sобщ',	
    'price': 'Цена РРЦ',
    'priceCalc': 'Сумма РРЦ',
    'priceMarkup': 'Сумма РРЦ с %',
  };
  jsonToCSV(jsonData: any[], orderInfo: any[], delimiter: string): string {
    const csvRows: string[] = [];
    const headers = Object.keys(jsonData[0]).filter(key => key !== '_id');;
    csvRows.push("\uFEFF"); 
    csvRows.push(`Создан${delimiter}Дата оплаты${delimiter}План-дата поступления на склад${delimiter}Факт-дата поступления на склад${delimiter}Номер${delimiter}Статус${delimiter}Сумма РРЦ${delimiter}Сумма РРЦ с %${delimiter}Оптовая сумма${delimiter}Лицо${delimiter}ФИО${delimiter}Sобщая${delimiter}Ответственный`);
    let orderHead = ''; //Информация о заказе
    for(const elem of orderInfo){
      orderHead += !elem ? `----${delimiter}` : elem+`${delimiter}`;
    }
    // csvRows.push(orderInfo.toString()+'\n');
    csvRows.push(orderHead+'\n');
    let header = '№'; // Информация о позициях
    for (const element of headers){
      header+=`${delimiter}${this.MAP_TO_RUS[element]}`;
    }
    csvRows.push(header); 
    // csvRows.push('№,'+headers.toString()); 
    // csvRows.push('№, Тип фасада, Толщина, Коллекция, Фрезеровка, Обкатная фреза, Декор, Текстура, Старение, Вид фасада, Высота, Ширина, Кол-во, S, Sобщ, Цена РРЦ, Сумма РРЦ, Сумма РРЦ с %'); 
    let index = 1;
    for (const row of jsonData) {
      const values = headers.map(header => {
        const value = row[header];
        const escaped = (value === null || value === undefined || value === '') ? '----' : ('' + value).replace(/"/g, '\\"'); // Check for empty value
        return `"${escaped}"`; // Wrap in quotes
    });
      csvRows.push(index+`${delimiter}`+values.join(`${delimiter}`));
      index++;
    }

    return csvRows.join('\n'); // Join rows with new line
  }

  downloadCSV(csvContent: string, filename: string): void {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
