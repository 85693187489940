import { Component } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { ShellLoginComponent } from './shell-login/shell-login.component';
import { HandsetService } from './services/handset.service';
import { AsyncPipe } from '@angular/common';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterModule, ShellComponent, ShellLoginComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'Fabfas';
  mobile = false;
  constructor(public router: Router, public handsetService: HandsetService, private location: Location){
    if(location.path() !== '/login' && location.path() !== '/register'){
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    // if(handsetService.isHandset$){
    //   console.log('mobile');
    // }
    // else{
    //   console.log('not');

    // }
  }
}
