import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const orderGuard: CanActivateFn = (route, state) => {
  let authService = inject(AuthService);
  if(authService.getOrderGuardCheck()){
    if(confirm('Данные заказа сбросятся, всё равно перейти на другую страницу?')){
      authService.removeOrderGuardCheck();
      return true;
    }
    return false;
  }

  if(authService.getCabinetGuardCheck()){
    if(confirm('Несохранённые изменения сбросятся, всё равно перейти на другую страницу?')){
      authService.removeCabinetGuardCheck();
      return true;
    }
    return false;
  }
  return true;
};
