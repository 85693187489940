<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<div class="page">
   @if(loadingOpt || loadingPrice){
    <h3>Загрузка</h3>
    <p class="error-message"> {{ errorMessage }} </p>
   }
   @else {
    <div class="black-page">
      <p class="error-message"> {{ errorMessage }} </p>
      <div class="main-options">
        <div class="label-select">
          <label for="typeF">Тип фасада <a target="_blank" href="https://fabfas.ru/">см.</a></label>
          <select class='text-design' id="typeF" [(ngModel)]="typeF" (change)="resetOptions(1)">
            <!-- @for (opt of ['PVC', 'LUX']; track $index) {
              <option> {{ opt }} </option>
            } -->
            <option> PVC </option>
            <option value="LUX"> LUX фасады </option>
            <option value="Fenix3D"> Fenix3D </option>
            <option value="kromka"> LUX кромка </option>
            <option selected disabled value="">----</option>
                 </select>
        </div>
        @if(typeF === 'PVC'){
          @if(optionsCount >= 1){
            <div class="label-select">
              <label for="collectPVC"><a target="_blank" href="https://fabfas.ru/collection-pvc/">Коллекция</a></label>
              <select class='text-design' id='collectPVC' [(ngModel)]="collectPVC" (change)="resetOptions(2)">
                @for (opt of options.collectPVC.entries; track $index) {
                  <option> {{ opt }} </option>
                }
                <option selected disabled value="">----</option>
              </select>
            </div>
          }
        @if(optionsCount >= 2){
          <div class="label-select">
            <label for="thickness">Толщина</label>
            <select class='text-design' id="thickness" [(ngModel)]="thickness" (change)="resetOptions(3)">
               @for (opt of options.collectPVC.thicknessDep.entries[collectPVC]; track $index) {
                 <option> {{ opt }} </option>
               }
               <option selected disabled value="">----</option>
             </select>
          </div>
        }
        @if(optionsCount >= 3){
          <div class="label-select">
            <label for="freza"><a target="_blank" href="https://fabfas.ru/wp-content/uploads/2022/02/obkat-frez-technical-document.pdf">Обкатная фреза</a></label>

            <select class='text-design' id="freza" [(ngModel)]="freza" (change)="resetOptions(4)">
              @if(collectPVC == 'Prestige' && thickness == '22'){
                <option>1</option>
                <option>2</option>
              }
              @else if(collectPVC == 'Standart' && thickness == '10'){
                <option>5</option>
              }
              @else {
                @for (opt of options.collectPVC.frezaDep.entries[collectPVC]; track $index) {
                  <option> {{ opt }} </option>
                }
              }
               <option selected disabled value="">----</option>
             </select>
          </div>
        }
        @if(optionsCount >= 4){
          <div class="label-select">
            <label for="milling">Фрезеровка</label>
            <select class='text-design' id="milling" [(ngModel)]="milling" (change)="resetOptions(5)">
              @if(collectPVC == 'Standart' && (thickness == '10' || thickness == '22' || thickness == '32' || thickness == '38')){
                <option> Модерн </option>
              } @else if (collectPVC == 'Prestige' && thickness == '22') {
                @if(freza == '1'){
                  <option> Гарда </option>
                }
                @if(freza == '2'){
                  <option> Гарда 2 </option>
                }
              }
              @else {
                @if(collectPVC == 'Prestige'){
                  @for (opt of options.milling.entries; track $index) {
                    @if(options.milling.collectDep.entries[opt] == collectPVC){
                      @if(!(opt == 'Гарда' || opt == 'Гарда 2')){
                        <option> {{ opt }} </option>
                      }
                    }
                  }
                }
                @else {
                  @for (opt of options.milling.entries; track $index) {
                    @if(options.milling.collectDep.entries[opt] == collectPVC){
                      <option> {{ opt }} </option>
                    }
                  }
                }
              }
            <option selected disabled value="">----</option>
          </select>
          </div>
        }
        @if(optionsCount >= 5){
          <div class="label-select">
            <label for="ispol">Вид фасада</label>
            <select class='text-design' id='ispol'[(ngModel)]="ispol" (change)="resetOptions(6)">
              @if(collectPVC == 'Standart' && (thickness == '10' || thickness == '22' || thickness == '32' || thickness == '38')){
                <option> Глухой </option>
              }
              @else{
                @for (opt of options.milling.ispolDep.entries[milling]; track $index) {
                  <option> {{ opt }} </option>
                }
              }
              <option selected disabled value="">----</option>
            </select>
          </div>
        }
        @if(optionsCount >= 6){
          <div class="label-select">
            <label for="decorPVC">Декор</label>
            <form class="example-form">
                <input #decorPVCHTML
                       type="text"
                       placeholder="----"
                       matInput
                       [formControl]="myControlPVC"
                       [matAutocomplete]="auto"
                       [(ngModel)]="decorPVC"
                       (input)="filterPVC()"
                       (focus)="filterPVC()"
                       class="filter-input"
                       >
                <mat-autocomplete requireSelection #auto="matAutocomplete" >
                  @for (option of filteredOptionsPVC; track option) {
                    @if(checkIntegra(option)){
                      <mat-option [value]="option">{{option}}</mat-option>
                    }
                  }
                </mat-autocomplete>
            </form>
          </div>
          <!-- <div class="label-select">
            <label for="decorPVC">Декор</label>
            <select class='text-design' id="decorPVC" [(ngModel)]="decorPVC" (change)="resetOptions(5)">
              @for (opt of options.decorPVC.entries; track $index) {
                @if(checkIntegra(opt) && checkStar(opt)){
                  <option> {{ opt }} </option>
                }
              }
              <option selected disabled value="">----</option>
            </select>
          </div>   -->
        }
        @if(optionsCount >= 7){
          @if(options.decorPVC.catalogDep.entries[decorPVC] == 'wood'){
            <div class="label-select">
              <label for="texture">Текстура</label>
              <select class='text-design' id="texture" [(ngModel)]="texture" (change)="resetOptions(8)">
                @for (opt of ['Горизонтальная', 'Вертикальная']; track $index) {
                  <option> {{ opt }} </option>
                }
                <option selected disabled value="">----</option>
              </select>
            </div>
          }
          @else if(optionsCount == 7) {
            {{ resetOptions(8) }}
          }
        }

        @if(optionsCount >= 8){
          @if(collectPVC != 'Integra' && collectPVC != 'Velvet' && !(collectPVC == 'Standart' && (thickness == '22' || thickness == '32' || thickness == '38'))){
            <div class="label-select">
              <label for="star">Эффект старения</label>
              <select class='text-design' id="star" [(ngModel)]="star" (change)="resetOptions(9)">
                @for (opt of options.decorPVC.starDep.entries[decorPVC]; track $index) {
                  <option> {{ opt }} </option>
                }
                <option selected value="">----</option>
              </select>
            </div>
          }
        }
          @if(optionsCount >= 9){
            @if(!(collectPVC == 'Standart' && (thickness == '22' || thickness == '32' || thickness == '38'))){
              <div class="label-select">
                <label for="polish">Лак</label>
                <select class='text-design' id="polish" [(ngModel)]="polish" (change)="polishCheck=true">
                  @for (opt of options.polish.entries; track $index) {
                    <option> {{ opt }} </option>
                  }
                  <option selected disabled value="">----</option>
                </select>
              </div>
            }
            <div class="label-select">
              <label for="hinge">Присадки</label>
              <select class='text-design' id="hinge" [(ngModel)]="hinge" (change)="hingesCheck = true">
                @for (opt of HINGES; track $index) {
                  <option> {{ opt }} </option>
                }
                <option selected value="">----</option>
              </select>
            </div>
        }
      }
      @if(typeF === 'LUX'){
        @if(optionsCount >= 1){
          <div class="label-select">
            <label for="collectLUX">Коллекция <a target="_blank" href="https://fabfas.ru/collection-abs/">см.</a></label>
            <select class='text-design' id='collectLUX' [(ngModel)]="collectLUX" (change)="resetOptions(2)">
              @for (opt of options.collectLUX.entries; track $index) {
                @if(opt !== 'Fenix3D Velvet' && opt !== 'Fenix3D Модерн'){
                  <option> {{ opt }} </option>
                }
              }
              <option selected disabled value="">----</option>
            </select>
          </div>
        }
        @if(optionsCount >= 2){
          <div class="label-select">
            <label for="thickness">Толщина</label>
            <select class='text-design' id="thickness" [(ngModel)]="thickness" (change)="resetOptions(3)">
              @for (opt of options.collectLUX.thicknessDep.entries[collectLUX]; track $index) {
                <option> {{ opt }} </option>
              }
              <option selected disabled value="">----</option>
            </select>
          </div>
        }
        @if(optionsCount >= 3){
          <div class="label-select">
            <label for="kromka">Кромка <a target="_blank" href="https://fabfas.ru/portfolio/edge/"> см.</a></label>
            <select class='text-design' id="kromka" [(ngModel)]="kromka" (change)="resetOptions(4)">
              @for (opt of options.kromka.entries; track $index) {
                @if(getPriceLUX(opt)){
                  <option> {{ opt }} </option>
                }
              }
              <option selected disabled value="">----</option>
            </select>
          </div>        }
        @if(optionsCount >= 4){
          @if(kromka == 'Дизайн кромка'){
            <div class="label-select">
              <label for="kromka-design">Дизайн кромки</label>
                <select class='text-design' id="kromka-design" [(ngModel)]="kromkaDesign" (change)="designCheck =true">
                  @for (opt of ['Дуб Поперечный', 'Золото','Медь','Алюминий','Зеркало','Серебро','Сталь','Титан'];track $index) {
                  <option> {{ opt }} </option>
                }
                <option selected disabled value="">----</option>
              </select>
            </div>
          }
          <!-- <div class="label-select">
            <label for="decorLUX">Декор</label>
            <select class='text-design' id="decorLUX" [(ngModel)]="decorLUX" (change)="resetOptions(5)">
              @for (opt of options.decorLUX.entries; track $index) {
                @if(options.decorLUX.collectDep.entries[opt] == collectLUX){
                  <option> {{ opt }} </option>
                }
              }
              <option selected disabled value="">----</option>
            </select>
            </div> -->
            <div class="label-select">
              <label for="decorLUX">Декор <a target="_blank" href="{{ options.collectLUX.linkDep.entries[(collectLUX)] }}"> см. </a></label>
              <form class="example-form">
                  <input #decorLUXHTML
                         type="text"
                         placeholder="----"
                         matInput
                         [formControl]="myControlLUX"
                         [matAutocomplete]="auto"
                         [(ngModel)]="decorLUX"
                         (input)="filterLUX()"
                         (focus)="filterLUX()"
                         class="filter-input"
                         >
                  <mat-autocomplete requireSelection #auto="matAutocomplete" >
                    @for (option of filteredOptionsLUX; track option) {
                      @if(options.decorLUX.collectDep.entries[option] == collectLUX){
                        <mat-option [value]="option">{{option}}</mat-option>
                      }
                    }
                  </mat-autocomplete>
              </form>
            </div>
          }
          @if(optionsCount >= 5){
            @if(options.collectLUX.catalogDep.entries[collectLUX] == 'wood' || options.decorLUX.catalogDep.entries[decorLUX] == 'wood'){
              <div class="label-select">
                <label for="texture">Текстура</label>
                <select class='text-design' id="texture" [(ngModel)]="texture">
                  @for (opt of ['Горизонтальная', 'Вертикальная']; track $index) {
                    <option> {{ opt }} </option>
                  }
                  <option selected disabled value="">----</option>
                </select>
              </div>
            }
            <div class="label-select">
              <label for="hinge">Присадки</label>
              <select class='text-design' id="hinge" [(ngModel)]="hinge" (change)="hingesCheck = true">
                @for (opt of HINGES; track $index) {
                  <option> {{ opt }} </option>
                }
                <option selected value="">----</option>
              </select>
            </div>
          }
        }
      @if(typeF === 'Fenix3D'){
        @if(optionsCount >= 1){
          <div class="label-select">
            <label for="collectLUX">Коллекция <a target="_blank" href="https://fabfas.ru/portfolio/fenix3d/">см.</a></label>
            <select class='text-design' id='collectLUX' [(ngModel)]="collectLUX" (change)="resetOptions(2)">
              @for (opt of options.collectLUX.entries; track $index) {
                @if(opt == 'Fenix3D Velvet' || opt == 'Fenix3D Модерн'){
                  <option> {{ opt }} </option>
                }
              }
              <option selected disabled value="">----</option>
            </select>
          </div>
        }
        @if(optionsCount >= 2){
          <div class="label-select">
            <label for="thickness">Толщина</label>
            <select class='text-design' id="thickness" [(ngModel)]="thickness" (change)="resetOptions(3)">
              @for (opt of options.collectLUX.thicknessDep.entries[collectLUX]; track $index) {
                <option> {{ opt }} </option>
              }
              <option selected disabled value="">----</option>
            </select>
          </div>
        }
        @if(optionsCount >= 4){
          <div class="label-select">
            <label for="decorLUX">Декор <a target="_blank" href="{{ options.collectLUX.linkDep.entries[(collectLUX)] }}"> см. </a></label>
            <form class="example-form">
                <input #decorLUXHTML
                       type="text"
                       placeholder="----"
                       matInput
                       [formControl]="myControlLUX"
                       [matAutocomplete]="auto"
                       [(ngModel)]="decorLUX"
                       (input)="filterLUX()"
                       (focus)="filterLUX()"
                       class="filter-input"
                       >
                <mat-autocomplete requireSelection #auto="matAutocomplete" >
                  @for (option of filteredOptionsLUX; track option) {
                    @if(options.decorLUX.collectDep.entries[option] == 'Fenix3D Модерн'){
                      <mat-option [value]="option">{{option}}</mat-option>
                    }
                  }
                </mat-autocomplete>
            </form>
          </div>
        }
        @if(optionsCount >= 5){
          <div class="label-select">
            <label for="hinge">Присадки</label>
            <select class='text-design' id="hinge" [(ngModel)]="hinge" (change)="hingesCheck = true">
              @for (opt of HINGES; track $index) {
                <option> {{ opt }} </option>
              }
              <option selected value="">----</option>
            </select>
          </div>
        }
      }
      @if(typeF === 'kromka'){
        @if(optionsCount >= 1){
          <div class="label-select">
            <label for="collectLUX">Коллекция <a target="_blank" href="https://fabfas.ru/collection-abs/">см.</a></label>
            <select class='text-design' id='collectLUX' [(ngModel)]="collectLUX" (change)="resetOptions(2)">
              @for (opt of options.collectLUX.entries; track $index) {
                <option> {{ opt }} </option>
              }
                <option value="design">Дизайн кромка</option>
              <option selected disabled value="">----</option>
            </select>
          </div>
        }
        @if(optionsCount >= 2 && collectLUX == 'design'){
          <div class="label-select">
            <label for="kromka-design">Дизайн кромки</label>
              <select class='text-design' id="kromka-design" [(ngModel)]="kromkaDesign" (change)="designCheck =true">
                @for (opt of ['Дуб Поперечный', 'Золото','Медь','Алюминий','Зеркало','Серебро','Сталь','Титан'];track $index) {
                <option> {{ opt }} </option>
              }
              <option selected disabled value="">----</option>
            </select>
          </div>
        } @else {
          <div class="label-select">
            <label for="decorLUX">Декор <a target="_blank" href="{{ options.collectLUX.linkDep.entries[(collectLUX)] }}"> см. </a></label>
            <form class="example-form">
                <input #decorLUXHTML
                       type="text"
                       placeholder="----"
                       matInput
                       [formControl]="myControlLUX"
                       [matAutocomplete]="auto"
                       [(ngModel)]="decorLUX"
                       (input)="filterLUX()"
                       (focus)="filterLUX()"
                       class="filter-input"
                       >
                <mat-autocomplete requireSelection #auto="matAutocomplete" >
                  @for (option of filteredOptionsLUX; track option) {
                    @if(options.decorLUX.collectDep.entries[option] == collectLUX){
                      <mat-option [value]="option">{{option}}</mat-option>
                    }
                  }
                </mat-autocomplete>
            </form>
          </div>
        }
      }
      </div>
      <div class="number-options">
        <div class="label-input">
          <label for="height">Высота (мм)</label>
          <input class="number-input" id="height" [(ngModel)]="height" (input)="resetMessages()" (focus)="resetMessages()" onclick="this.select()"
          [disabled]="typeF === 'kromka'"/>
        </div>
        <div class="label-input">
          <label for="width">Ширина (мм)</label>
          <input class="number-input" id="width" (input)="resetMessages()" (focus)="resetMessages()"  [(ngModel)]="width"onclick="this.select()"
          [disabled]="typeF === 'kromka'"/>
        </div>
        <div class="label-input">
          <label for="amount">Кол-во</label>
          <input
            class="number-input red-border"
            id="amount"
            [(ngModel)]="amount" (input)="resetMessages()" (focus)="resetMessages()"
            onclick="this.select()"
          />
        </div>
        <div class="label-input">
          <label for="addorder" class="green added-order">{{addedOrder}}</label>
          <button id="addorder" (click)="addOrder()" class="add-order-button text-design">
            Добавить к заказу
          </button>
        </div>
        <div class="markup-block">
          <div class="inline-block">
            @if(statusCheck && !incogintoMode && person !== 'Сотрудник производителя мебели'){
              <div class="label-input label-markup">
                <label for="markup">Ваша наценка в %</label>
                <input
                  class="number-input markup-input"
                  id="markup"
                  onclick="this.select()" (input)="resetMessages()" (focus)="resetMessages()"
                  [(ngModel)]="markup"
                />
              </div>
              <div>
                <button class="text-design markup-button-2" (click)="doMarkup()">Применить наценку</button>
              </div>
              }
          </div>
          <div class="relative-markup">
              @if(statusCheck && !incogintoMode && person !== 'Сотрудник производителя мебели'){
                <button
                  class="markup-button text-design lower"
                  (click)="incogintoMode=!incogintoMode">Скрыть
                </button>
              }
              @if(statusCheck && incogintoMode && person !== 'Сотрудник производителя мебели'){
                <button
                  class="markup-button text-design lower"
                  (click)="incogintoMode=!incogintoMode">Показать
                </button>
              }
          </div>
          <div class="for-whom">
            <!-- @if(roleCheck && bitrixWorkID){ -->
            @if(roleCheck){
                  <div class="label-input label-markup">
                    <label for="for-whom">Для кого</label>
                    <input
                      class="number-input markup-input"
                      id="for-whom"
                      placeholder="80001234567"
                      onclick="this.select()" (input)="resetMessages()" (focus)="resetMessages()"
                      [(ngModel)]="phoneForWho"
                    />
                  </div>
              }
          </div>
        </div>
      </div>
      <div></div>
      <div class="comment-options">
      <div class="label-input">
        <label for="msgCompany" class="label-padding">Комментарий для менеджера</label>
        <textarea
          class="comment-input"
          type="text"
          id="msgCompany"
          placeholder="Укажите свой комментарий к заказу. Например: «До заказ к заказу …» или «Нужна будет доставка по адресу …», или «Самовывоз»"
          maxlength="200"
          [(ngModel)]="messageForCompany"
        ></textarea>
      </div>
      @if(statusCheck){
        <div class="label-input-comment">
          <label for="msgClient" class="label-padding">Комментарий о вашем заказчике</label>
          <textarea
            class="comment-input"
            type="text"
            id="msgClient"
            maxlength="200"
            placeholder="Для удобства дальнейшего поиска укажите ФИО вашего заказчика или номер вашего договора с заказчиком"
            [(ngModel)]="messageForClient"
          ></textarea>
        </div>
      }
      @if(person !== 'Сотрудник производителя мебели'){
        <div class="payment">
          <div>
            <label for="paymentPlan">Планируемая форма оплаты</label>
            <select class='text-design payment-select' id="paymentPlan" [(ngModel)]="paymentPlanForm">
              @for (opt of ['Наличные, как физическое лицо','Картой физического лица','Перевод на счёт с карты физического лица','Расчётный счёт от юридического лица']; track $index) {
                <option> {{ opt }} </option>
              }
              <option selected disabled value="">----</option>
            </select>
          </div>
          <div>
            <button class="save-order text-design" (click)="createOrder('Сохранён')">
              Сохранить заказ
            </button>
          </div>
        </div>
        <div class="payment payment-date">
          <div>
            <label for="paymentDate">Планируемая дата оплаты</label>
            <input [ngModel]="paymentDate | date:'yyyy-MM-dd'"
            (ngModelChange)="paymentDate = $event"
            type="date" id="paymentDate" class="input-date" value="{{paymentDate}}"/>
          </div>
          <div>
            <button class="create-order text-design" (click)="createOrder('В обработке у менеджера')">
              Получить счёт
            </button>
          </div>
        </div>
      }@else{
        <div class="payment">
          <div style="visibility: hidden;">
            <label for="paymentPlan">Планируемая форма оплаты</label>
            <select class='text-design payment-select' id="paymentPlan" [(ngModel)]="paymentPlanForm">
              @for (opt of ['Наличные, как физическое лицо','Картой физического лица','Перевод на счёт с карты физического лица','Расчётный счёт от юридического лица']; track $index) {
                <option> {{ opt }} </option>
              }
              <option selected disabled value="">----</option>
            </select>
          </div>
          <div>
            <button class="save-order text-design" (click)="createOrder('Сохранён')">
              Сохранить заказ
            </button>
          </div>
        </div>
      }
      </div>
    </div>
    <div class="white-page">
     <div class="done-helper">
      <table>
        <tr>
          <td class="pending-data-no-border">
            <p>№</p>
          </td>
          <td class="pending-data-no-border">
            <p>Тип фасада</p>
          </td>
          <td class="pending-data-no-border">
            <p>Толщина</p>
          </td>
          <td class="pending-data-no-border">
            <p>Коллекция</p>
          </td>
          @if (frezaCheck) {
            <td class="pending-data-no-border data-short">
              <p>Обк. Фреза</p>
            </td>
          }
          <td class="pending-data-no-border">
            <p>Фрезеровка</p>
          </td>
          <td class="pending-data-no-border">
            <p>Декор</p>
          </td>
          @if(kromkaCheck){
            <td class="pending-data-no-border">
              <p>Кромка</p>
            </td>
          }
          @if(designCheck){
            <td class="pending-data-no-border">
              <p>Дизайн кромки</p>
            </td>
          }
          <td class="pending-data-no-border data-long">
            <p>Текстура</p>
          </td>
          @if (starCheck) {
            <td class="pending-data-no-border data-short">
              <p>Старение</p>
            </td>
          }
          @if (polishCheck) {
            <td class="pending-data-no-border data-short">
              <p>Лак</p>
            </td>
          }
          <td class="pending-data-no-border">
            <p>Вид фасада</p>
          </td>
          @if(hingesCheck){
            <td class="pending-data-no-border data-short">
              <p>Присадки</p>
            </td>
          }
          <td class="pending-data-no-border data-short">
            <p>Высота</p>
          </td>
          <td class="pending-data-no-border data-short">
            <p>Ширина</p>
          </td>
          <td class="pending-data-no-border data-short">
            <p>Кол-во</p>
          </td>
          <td class="pending-data-no-border">
            <p>S<sub>детали</sub></p>
          </td>
          <td class="pending-data-no-border">
            <p>S<sub>общая</sub></p>
          </td>
          <td class="pending-data-no-border data-short">
            <p>Цена РРЦ</p>
          </td>
          <td class="pending-data-border-right data-short">
            <p>Сумма РРЦ</p>
          </td>
          @if (statusCheck && !incogintoMode) {
          <td class="pending-data-border-left data-short">
            <p>Сумма РРЦ с %</p>
          </td>
          }
        </tr>
        @for (sub of doneOrders; track $index) {
        <tr>
          <td class="pending-data-no-border-sub" matTooltip="Скопировать" matTooltipPosition="above" (click)="copy($index)"> {{ $index+1 }} <i class="fas fa-copy"></i> </td>
          <td class="pending-data-no-border-sub"> {{ sub.typeF }} </td>
          <td class="pending-data-no-border-sub"> {{ sub.thickness }} </td>
          <td class="pending-data-no-border-sub"> {{ sub.collect }} </td>
          @if (frezaCheck) {
            <td class="pending-data-no-border-sub"> {{ sub.freza }} </td>
          }
          <td class="pending-data-no-border-sub">
            @if(sub.typeF==='PVC'){
              <a target="_blank" href="{{ options.milling.linkDep.entries[(sub.milling)!.toString()] }}"> {{ sub.milling }} </a>
            }
            @else { ---- }
          </td>
          <td class="pending-data-no-border-sub">
            @if(sub.typeF==='PVC'){
              <a target="_blank" href="{{ options.decorPVC.linkDep.entries[(sub.decor)!.toString()] }}"> {{ sub.decor }} </a>
            } @else if(sub.typeF ==='LUX' || sub.typeF === 'Fenix3D') {
              <a target="_blank" href="{{ options.decorLUX.linkDep.entries[(sub.decor)!.toString()] }}"> {{ sub.decor }} </a>
            } @else if(sub.typeF === 'LUX кромка') {
              ----
            }
          </td>
          @if(kromkaCheck){
            <td class="pending-data-no-border-sub">
              @if(sub.typeF==='LUX' && !!sub.kromka){ {{ MAP_KROMKA[sub.kromka] }} }
              @else{ ---- }
            </td>
          }
          @if(designCheck){
            <td class="pending-data-no-border-sub">
              @if(!sub.kromkaDesign) {  ----  }
              @else { {{ sub.kromkaDesign }} }
            </td>
          }
          <td class="pending-data-no-border-sub data-long">
            @if(!sub.texture) {  ----  }
            @else { {{ MAP_TEXTURE[sub.texture] }} }
          </td>
          @if(starCheck){
            <td class="pending-data-no-border-sub data-short">
              @if(!sub.star) {  ----  }
              @else { {{ MAP_STAR[sub.star] }} }
            </td>
          }
          @if(polishCheck){
            <td class="pending-data-no-border-sub data-short">
              @if(!sub.polish) {  ----  }
              @else { {{ sub.polish }} }
            </td>
          }
          <td class="pending-data-no-border-sub">
            @if(sub.typeF==='PVC'){
            {{ sub.ispol }}
            }@else{
              Глухой
            }
          </td>
          @if(hingesCheck){
            <td class="pending-data-no-border-sub data-short">
              @if(!sub.hinge) {  ----  }
              @else { {{ sub.hinge }} }
            </td>
          }
          <td class="pending-data-no-border-sub data-short">
            @if(sub.typeF === 'LUX кромка'){
              ----
            }@else{
              {{ sub.height | number }}
            }
          </td>
          <td class="pending-data-no-border-sub data-short">
            @if(sub.typeF === 'LUX кромка'){
              ----
            }@else{
              {{ sub.width | number}}
            }
          </td>
          <td class="pending-data-no-border-sub data-short"> {{ sub.amount }} @if(sub.typeF == 'LUX кромка'){м.п.}</td>
          <td class="pending-data-no-border-sub data-short">
            @if(sub.typeF === 'LUX кромка'){
              ----
            }@else{
              {{ sub.area | number}}
              м<sup>2</sup>
            }
          </td>
          <td class="pending-data-no-border-sub data-short">
            @if(sub.typeF === 'LUX кромка'){
              ----
            }@else{
              {{ sub.totalArea | number}}
              м<sup>2</sup>
            }
          </td>
          <td class="pending-data-no-border-sub data-short">
            @if(incogintoMode){
              {{ ceil(sub.price * (1 + markup / 100)) }}
            }@else {
              {{ sub.price }}
            }
          </td>
          <td class="pending-data-border-right-sub data-short">
            @if(incogintoMode){
              {{ sub.priceMarkup | number}}
            }@else {
              {{ sub.priceCalc | number}}
            }
           </td>
          @if(statusCheck && !incogintoMode){
            <td class="pending-data-border-left-sub data-short"> {{ sub.priceMarkup | number }} </td>
          }
          <button
            mat-mini-fab
            class="delete-button"
            (click)="removeSuborder($index)"
          >
            <img src="\assets\cross.png" width="25" height="25" />
          </button>
        </tr>
        }
        <tr>
          <td class="pending-data-no-border-sub" style="font-weight: bold;">Итого</td>
          <td class="pending-data-no-border-sub"></td>
          <td class="pending-data-no-border-sub"></td>
          <td class="pending-data-no-border-sub"></td>
          <td class="pending-data-no-border-sub"></td>
          <td class="pending-data-no-border-sub"></td>
          <td class="pending-data-no-border-sub"></td>
          <td class="pending-data-no-border-sub"></td>
          @if(kromkaCheck){
            <td class="pending-data-no-border-sub"></td>
          }
          @if(designCheck){
            <td class="pending-data-no-border-sub"></td>
          }
          @if(frezaCheck){
            <td class="pending-data-no-border-sub"></td>
          }
          @if(starCheck){
            <td class="pending-data-no-border-sub"></td>
          }
          @if(polishCheck){
            <td class="pending-data-no-border-sub"></td>
          }
          @if(hingesCheck){
            <td class="pending-data-no-border-sub">
              {{hingesAmount}}
            </td>
          }
          <td class="pending-data-no-border-sub"></td>
          <td class="pending-data-no-border-sub"></td>
          <td class="pending-data-no-border-sub"> {{ totalAmount }}</td>
          <td class="pending-data-no-border-sub"></td>
          <td class="pending-data-no-border-sub"> {{ totalArea }} м<sup>2</sup></td>
          <td class="pending-data-no-border-sub"></td>
          @if(lessThan1SqM){
            <td class="pending-data-border-right-sub" style="text-decoration: underline; cursor: wait;"  title="Sобщая меньше 1 кв.м">
                @if(incogintoMode){
                  {{ totalPriceLessThan1sqMMarkup | number }}
                }@else {
                  {{ totalPriceLessThan1sqM | number}}
                }
             </td>
          }
          @else{
            <td class="pending-data-border-right-sub">
              @if(incogintoMode){
                {{ totalPriceMarkup | number }}
              }@else {
                {{totalPrice | number}}
              }
            </td>
          }
          @if (statusCheck && !incogintoMode) {
            <td class="pending-data-border-left-sub">
              @if(lessThan1SqM){
                {{ totalPriceLessThan1sqMMarkup | number }}
              }@else{
                {{ totalPriceMarkup | number }}

              }
            </td>
          }
        </tr>
      </table>
    </div>
  </div>
  }
 </div>
