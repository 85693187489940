<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<div class="white-page">
  @if (loading) {
  <h2>Загрузка данных</h2>
  } @else{
  <div class="border-radius">
    <table>
      <thead>
        <tr>
          <th>№</th>
          <th (click)="sort('name')">
            <span style="text-wrap: nowrap;">
              ФИО <i class="fa" [ngClass]="{'fa-sort': sortField !== 'name', 'fa-sort-up': sortField === 'name' && sortOrder === 'asc', 'fa-sort-down': sortField === 'name' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th>Телефон</th>
          <th>Почта</th>
          <th (click)="sort('role')">
            <span style="text-wrap: nowrap;">
              Роль <i class="fa" [ngClass]="{'fa-sort': sortField !== 'role', 'fa-sort-up': sortField === 'role' && sortOrder === 'asc', 'fa-sort-down': sortField === 'role' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('person')">
            <span style="text-wrap: nowrap;">
              Лицо <i class="fa" [ngClass]="{'fa-sort': sortField !== 'person', 'fa-sort-up': sortField === 'person' && sortOrder === 'asc', 'fa-sort-down': sortField === 'person' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('company')">
            <span style="text-wrap: nowrap;">
              Форма <i class="fa" [ngClass]="{'fa-sort': sortField !== 'company', 'fa-sort-up': sortField === 'company' && sortOrder === 'asc', 'fa-sort-down': sortField === 'company' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('companyName')">
            Название
            <span style="text-wrap: nowrap;">
              фирмы <i class="fa" [ngClass]="{'fa-sort': sortField !== 'companyName', 'fa-sort-up': sortField === 'companyName' && sortOrder === 'asc', 'fa-sort-down': sortField === 'companyName' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th>ИНН</th>
          <th (click)="sort('lastSeen')">
            Последний <span style="text-wrap: nowrap;">
              визит <i class="fa" [ngClass]="{'fa-sort': sortField !== 'lastSeen', 'fa-sort-up': sortField === 'lastSeen' && sortOrder === 'asc', 'fa-sort-down': sortField === 'lastSeen' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('verified')">
            <span style="text-wrap: nowrap;">
              Статус <i class="fa" [ngClass]="{'fa-sort': sortField !== 'verified', 'fa-sort-up': sortField === 'verified' && sortOrder === 'asc', 'fa-sort-down': sortField === 'verified' && sortOrder === 'desc'}"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        @for (user of users; track $index) {
        <tr>
          <td>{{ $index+1 }}</td>
          <td>{{ user.name }}</td>
          <td>
            <a href="/client/{{ user._id }}" class="number-text">{{ user.phone }}</a>
          </td>
          <td>
            @if(!user.mail){
              ----
            }
            {{ user.mail }}
          </td>
          <td>{{ user.role }}</td>
          <td>{{ user.person }}</td>
          <td>
            @if(!user.company){
              ----
            }
            {{ user.company }}
          </td>
          <td>
            @if(!user.companyName){
              ----
            }
            {{ user.companyName }}
          </td>
          <td>
            @if(!user.inn){
            ----
            }
            {{ user.inn }}
          </td>
          <td [ngClass]="{'red': checkOld(user.lastSeen)}">
            @if(!user.lastSeen){
            ----
            }
              {{ user.lastSeen  | date:'dd.MM.yyyy' }}
          </td>
          @if (user.verified) {
          <td>Подтверждён</td>
          }@else{
          <td>Неподтверждён</td>
          }
        </tr>
        }
      </tbody>
    </table>
  </div>
  }
</div>
