import { BreakpointObserver, Breakpoints, LayoutModule } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandsetService {
  isHandset$: Observable<boolean> = this.breakpointObserver
  .observe([Breakpoints.Handset])
  .pipe(
    map((result) => result.matches),
    shareReplay()
  );
constructor(
  private breakpointObserver: BreakpointObserver,
) {}
  
}
