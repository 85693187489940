import { Component, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import {
  Options,
  collectLUX,
  collectPVC,
  decorLUX,
  decorPVC,
  ispol,
  kromka,
  milling,
  polish,
  star,
  thickness,
} from '../interfaces/options';
import { Price } from '../interfaces/price';
import { FormsModule } from '@angular/forms';
import { Order, SubOrder } from '../interfaces/order';
import { MatButtonModule, MatMiniFabButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { DatePipe, NgClass } from '@angular/common';
import { NumberPipe } from '../pipes/number.pipe';

import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-create-order',
  standalone: true,
  imports: [FormsModule, MatMiniFabButton, NgClass, NumberPipe, DatePipe, MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,MatButtonModule, MatTooltipModule],
  templateUrl: './create-order.component.html',
  styleUrl: './create-order.component.scss',
})
export class CreateOrderComponent {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
  ) {
    apiService.getOptions().subscribe((data: Options) => {
      if ('message' in data) {
        //@ts-ignore
        this.errorMessage = JSON.stringify(data.message);
        return;
      }
      if (data) {
        this.loadingOpt = false;
        // this.errorMessage = '';
        this.options = data;
      } else {
        this.errorMessage = 'Произошла ошибка.';
      }
    });
    apiService.getPrice().subscribe((data: Price) => {
      if ('message' in data) {
        //@ts-ignore
        this.errorMessage = JSON.stringify(data.message);
        return;
      }
      if (data) {
        this.loadingPrice = false;
        // this.errorMessage = '';
        this.price = data;
      } else {
        this.errorMessage = 'Произошла ошибка.';
      }
    });
    authService.getCookie().subscribe((user)=>{
      let role = user.role;
      let person = user.person;
      this.phone = user.phone;
      this.name = user.name;
      if(user.bitrixWorkID){
        this.bitrixWorkID = user.bitrixWorkID;
      }
      if(user.parentID){
        this.parentID = user.parentID;
      }
      if (
        role === 'Менеджер' ||
        role === 'Админ' ||
        role === 'Владелец')
      {
        this.statusCheck = true;
        this.roleCheck = true;
      }
      if (person !== 'Розничный покупатель'){
        this.statusCheck = true;
      }
      this.person = person;

    });
    const order_check = localStorage.getItem('order');
    if(order_check){
      const ORDER_COPIED = JSON.parse(order_check) as Order;
      if(ORDER_COPIED.totalPrice){
        this.totalPrice = ORDER_COPIED.totalPrice;
      }
      if(ORDER_COPIED.totalPriceMarkup){
        this.totalPriceMarkup = ORDER_COPIED.totalPriceMarkup;  
      }
      if(ORDER_COPIED.totalArea){
        this.totalArea = ORDER_COPIED.totalArea;
      }
      if(ORDER_COPIED.commentsForCompany){
        this.messageForCompany = ORDER_COPIED.commentsForCompany;
      }
      if(ORDER_COPIED.commentsForClient){
        this.messageForClient = ORDER_COPIED.commentsForClient;
      }
      if(ORDER_COPIED.order){
        this.doneOrders = ORDER_COPIED.order;
      }
      if(ORDER_COPIED.createdResponsible){
        this.phoneForWho = ORDER_COPIED.phone;
      }
      if(ORDER_COPIED.phoneChildren && !this.parentID){
        this.childrenCheck = true;
        if(ORDER_COPIED.phoneChildren){
          this.parentID = ORDER_COPIED.phoneChildren;
        }
      }
      if(ORDER_COPIED.order == undefined){
        this.errorMessage = 'Заказ пуст или сломан.';
        return;
      }
      for(const order of ORDER_COPIED.order){
        this.totalAmount += order.amount;
        if(order.hinge){
          this.hingesAmount += order.amount * this.HINGES_AMOUNT[order.hinge];
        }
        if (order.freza !== undefined && order.freza !== '') {
          this.frezaCheck = true;
        }
        if (order.kromka !== undefined && order.kromka !== '') {
          this.kromkaCheck = true;
        }
        if (order.kromkaDesign !== undefined && order.kromkaDesign !== '') {
          this.designCheck = true;
        }
        if (order.star !== undefined && order.star !== '') {
          this.starCheck = true;
        }
        if (order.polish !== undefined && order.polish !== '') {
          this.polishCheck = true;
        }
        if (order.hinge && order.hinge !== undefined && order.hinge !== '') {
          this.hingesCheck = true;
        }
      }
      localStorage.removeItem('order');
      this.errorMessage = 'Заказ был успешно скопирован.';
    }
    this.authService.setOrderGuardCheck();
  }

  LESS_THAN_02_MARKUP = 1.25;
  LESS_THAN_1_MARKUP = 1.5;
  LESS_THAN_100_MARKUP = 1.5;

  MAP_TEXTURE: { [key: string]: string } = {
    '': '',
    'Вертикальная': '↑',
    'Горизонтальная': '→',
  };

  MAP_STAR: { [key: string]: string } = {
    '': '',
    'Без старения': 'Без ст.',
    'Полная патина': 'Полн.',
    'Стандтартная патина': 'Стд.',
  };

  MAP_KROMKA: { [key: string]: string } = {
    '': '',
    'Без кромки': 'Без',
    'Дизайн кромка': 'Дизайн',
    'Кромка в цвет': 'В цвет',
  };

  MAP_ERROR: { [key: string]: string } = {
    'typeF': '0',
    'collectPVC': '0',
    'thickness': '0',
    'freza': '0',
    'milling': '0',
    'ispol': '0',
    'decorPVC': '0',
    'texture': '0',
    'star': '0',
    'polish': '0',
    'collectLUX': '0',
    'kromka': '0',
    'kromkaDesign': '0',
    'decorLUX': '0',
    'height': '0',
    'width': '0',
    'amount': '0',
    'paymentPlanForm': '0',
    'paymentDate': '0',
  };

  KROMKA_PRICE: { [key: string]: number } = {
    'Acrylux': 667.59,
    'Mattelux': 508.64,
    'Premier Matt': 112.2,
    'Ecolux': 508.64,
    'Softlux': 508.64,
    'Woodlux': 508.64,
    'design': 1001.39,
  };

  HINGES = [
    "2 ГОР 100",
    "2 ГОР 50",
    "2 Л 100",
    "2 Л 50",
    "2 ПР 100",
    "2 ПР 50",
    "3 ГОР 100",
    "3 ГОР 50",
    "3 Л 100",
    "3 Л 50",
    "3 ПР 100",
    "3 ПР 50",
    "4 Л 100",
    "4 Л 50",
    "4 ПР 100",
    "4 ПР 50",
    "5 Л 100",
    "5 Л 50",
    "5 ПР 100",
    "5 ПР 50",
    "6 Л 100",
    "6 Л 50",
    "6 ПР 100",
    "6 ПР 50",
    "7 Л 100",
    "7 Л 50",
    "7 ПР 100",
    "7 ПР 50",
    "8 Л 100",
    "8 Л 50",
    "8 ПР 100",
    "8 ПР 50"
];

HINGES_AMOUNT: {[key: string]: number} = {
    '2 ГОР 100': 2,
    '2 ГОР 50': 2,
    '2 Л 100': 2,
    '2 Л 50': 2,
    '2 ПР 100': 2,
    '2 ПР 50': 2,
    '3 ГОР 100': 3,
    '3 ГОР 50': 3,
    '3 Л 100': 3,
    '3 Л 50': 3,
    '3 ПР 100': 3,
    '3 ПР 50': 3,
    '4 Л 100': 4,
    '4 Л 50': 4,
    '4 ПР 100': 4,
    '4 ПР 50': 4,
    '5 Л 100': 5,
    '5 Л 50': 5,
    '5 ПР 100': 5,
    '5 ПР 50': 5,
    '6 Л 100': 6,
    '6 Л 50': 6,
    '6 ПР 100': 6,
    '6 ПР 50': 6,
    '7 Л 100': 7,
    '7 Л 50': 7,
    '7 ПР 100': 7,
    '7 ПР 50': 7,
    '8 Л 100': 8,
    '8 Л 50': 8,
    '8 ПР 100': 8,
    '8 ПР 50': 8

}

HINGES_PRICE = 93.5;

  //@ts-ignore
  @ViewChild('decorLUXHTML') decorLUXHTML: ElementRef<HTMLInputElement>;
  previousLUX: string = '';
  myControlLUX = new FormControl('');
  filteredOptionsLUX: string[] = [];

  filterLUX(): void {
    const filterValueLUX = this.decorLUXHTML.nativeElement.value;
    this.filteredOptionsLUX = this.options.decorLUX.entries.filter(o => o.toLowerCase().includes(filterValueLUX.toLowerCase()));
    if(this.options.decorLUX.entries.includes(filterValueLUX) && (filterValueLUX !== this.previousLUX)){
      this.resetOptions(5);
    }
    if(this.options.decorLUX.catalogDep.entries[this.decorLUX] == 'wood'){
      this.texture = 'Вертикальная';
    }
    this.previousLUX = filterValueLUX;
  }

  //@ts-ignore
  @ViewChild('decorPVCHTML') decorPVCHTML: ElementRef<HTMLInputElement>;
  previousPVC: string = '';
  myControlPVC = new FormControl('');
  filteredOptionsPVC: string[] = [];

  filterPVC(): void {
    const filterValuePVC = this.decorPVCHTML.nativeElement.value;
    this.filteredOptionsPVC = this.options.decorPVC.entries.filter(o => o.toLowerCase().includes(filterValuePVC.toLowerCase()));
    if(this.options.decorPVC.entries.includes(filterValuePVC) && (filterValuePVC !== this.previousPVC)){
      this.resetOptions(8);
    }
    if(this.options.decorPVC.catalogDep.entries[this.decorPVC] == 'wood'){
      this.texture = 'Вертикальная';
    }
    this.previousPVC = filterValuePVC;
  }

  phone: string = '';
  parentID: string = '';
  bitrixWorkID: string = '';
  phoneForWho: string = '';
  name: string = '';
  person: string = '';

  options!: Options;
  price!: Price;

  errorMessage: string = '';
  addedOrder: string = '';

  loadingOpt: boolean = true;
  loadingPrice: boolean = true;
  statusCheck: boolean = false;
  roleCheck: boolean = false;


  doneOrders: SubOrder[] = [];

  textureArr: string[] = ['Вертикальная', 'Горизонтальная'];

  typeF: string = '';
  thickness: string = '';
  collectPVC: string = '';
  collectLUX: string = '';
  milling: string = '';
  freza: string = '';
  decorPVC: string = '';
  decorLUX: string = '';
  kromka: string = '';
  kromkaDesign: string = '';
  star: string = '';
  ispol: string = '';
  hinge: string = '';
  polish: string = 'Без лака';
  texture: 'Вертикальная' | 'Горизонтальная' | '' = '';
  // viewF: string = '';

  incogintoMode: boolean = false;

  height: number = 0;
  width: number = 0;
  amount: number = 0;
  totalAmount: number = 0;
  hingesAmount: number = 0;
  area: number = 0;
  totalAreaOne: number = 0;
  totalArea: number = 0;

  priceCalc: number = 0;
  priceMarkup: number = 0;
  pricePerMeter: number = 0;
  paymentPlanForm: string = '';

  // paymentDate:Date = new Date();
  // @ts-ignore
  paymentDate:Date;

  markup: number = 0;
  markupConst: number = 0;

  totalPrice: number = 0;
  totalPriceMarkup: number = 0;
  totalPriceLessThan1sqM: number = 0;
  totalPriceLessThan1sqMMarkup: number = 0;

  messageForClient: string = '';
  messageForCompany: string = '';

  optionsCount = 0;

  lessThan1SqM: boolean = false;
  kromkaCheck: boolean = false;
  starCheck: boolean = false;
  frezaCheck: boolean = false;
  polishCheck: boolean = false;
  checkExplain: boolean = false;
  designCheck: boolean = false;
  hingesCheck: boolean = false;
  
  childrenCheck: boolean = false;


  createOrder(statusStr: string) {
    if(this.totalPrice == 0){
      this.errorMessage = "Заказ пуст";
      return;
    }
    if(statusStr != 'Сохранён' && !this.paymentPlanForm){
      this.errorMessage = "Не указана форма оплаты";
      return;
    }
    if(statusStr != 'Сохранён' && !this.paymentDate){
      this.errorMessage = "Не указана планируемая дата оплаты";
      return;
    }
    if(this.phoneForWho){
      if(!this.validatePhone(this.phoneForWho)){
        this.errorMessage = 'Формат телефона должен быть 89821234567';
        return;
      }
      this.apiService.getUserByPhone(this.phoneForWho).subscribe((data)=>{
        if(!data){
          this.errorMessage = 'Пользователь не существует';
          return;
        }else{
          this.confirmAndCreateOrder(statusStr);
        }
      });
    }else{
      this.confirmAndCreateOrder(statusStr);
    }   
  }

  addOrder() {
    this.errorMessage = '';
    this.addedOrder = '';

    if(!this.canCalcPrice()){
      return;
    }

    this.calcPrice();

    if (this.typeF === 'LUX') {
      if(!this.typeF || !this.thickness || !this.collectLUX
        || !this.kromka || !this.decorLUX
      ){
        this.errorMessage = "Не все параметры указаны";
        return;
      }
      if(this.options.collectLUX.catalogDep.entries[this.collectLUX] === 'wood'){
        if(!this.texture){
          this.errorMessage = "Не все параметры указаны";
          return;
        }
      }
      let sub: SubOrder = {
        typeF: this.typeF,
        thickness: this.thickness,
        collect: this.collectLUX,
        decor: this.decorLUX,
        kromka: this.kromka,
        texture: this.texture,
        height: this.height,
        width: this.width,
        amount: this.amount,
        area: this.area,
        ispol: 'Глухой',
        totalArea: this.totalAreaOne,
        price: this.pricePerMeter,
        priceCalc: this.priceCalc,
      };
      if (this.statusCheck) {
        sub['priceMarkup'] = this.priceMarkup;
        this.totalPriceMarkup += this.priceMarkup;
      }
      if(this.kromkaDesign){
        sub['kromkaDesign'] = this.kromkaDesign;
      }
      if(this.hinge){
        sub['hinge'] = this.hinge;
        this.hingesAmount += this.amount * this.HINGES_AMOUNT[this.hinge];
      }
      this.totalPrice += this.priceCalc;
      this.totalArea += this.totalAreaOne;
      this.totalArea = +this.totalArea.toFixed(2);
      this.totalAmount += +this.amount;

      this.addedOrder = 'Позиция добавлена';
      this.doneOrders.push(sub);
    } else if (this.typeF === 'PVC'){
      if(!this.typeF || !this.thickness || !this.collectPVC
        || !this.milling || !this.decorPVC || !this.ispol || !this.freza
      ){
        this.errorMessage = "Не все параметры указаны";
        return;
      }
      if(this.options.decorPVC.catalogDep.entries[this.decorPVC] === 'wood'){
        if(!this.texture){
          this.errorMessage = "Не все параметры указаны";
          return;
        }
      }
      let sub: SubOrder = {
        typeF: this.typeF,
        thickness: this.thickness,
        collect: this.collectPVC,
        decor: this.decorPVC,
        milling: this.milling,
        ispol: this.ispol,
        star: this.star,
        polish: this.polish,
        texture: this.texture,
        freza: this.freza,
        height: this.height,
        width: this.width,
        amount: this.amount,
        area: this.area,
        totalArea: this.totalAreaOne,
        price: this.pricePerMeter,
        priceCalc: this.priceCalc,
      };
      if(this.star){
        sub['star'] = this.star;
      }
      if(this.polish){
        sub['polish'] = this.polish;
      }
      if (this.statusCheck) {
        sub['priceMarkup'] = this.priceMarkup;
        this.totalPriceMarkup += this.priceMarkup;
      }
      if(this.hinge){
        sub['hinge'] = this.hinge;
        this.hingesAmount += this.amount * this.HINGES_AMOUNT[this.hinge];
      }
      this.totalPrice += this.priceCalc;
      this.totalArea += this.totalAreaOne;
      this.totalArea = +this.totalArea.toFixed(2);
      this.totalAmount += +this.amount;

      this.lessThan1SqM = this.totalArea < 1;

      if(this.lessThan1SqM){
        this.totalPriceLessThan1sqM = Math.ceil(this.totalPrice*this.LESS_THAN_1_MARKUP);
        this.totalPriceLessThan1sqMMarkup = Math.ceil(this.totalPriceMarkup*this.LESS_THAN_1_MARKUP);
      }

      this.doneOrders.push(sub);
      this.addedOrder = 'Позиция добавлена';
    }else if(this.typeF === 'Fenix3D'){
      let sub: SubOrder = {
        typeF: this.typeF,
        thickness: this.thickness,
        collect: this.collectLUX,
        decor: this.decorLUX,
        kromka: this.kromka,
        height: this.height,
        width: this.width,
        amount: this.amount,
        area: this.area,
        ispol: 'Глухой',
        totalArea: this.totalAreaOne,
        price: this.pricePerMeter,
        priceCalc: this.priceCalc,
      }
      
      if (this.statusCheck) {
        sub['priceMarkup'] = this.priceMarkup;
        this.totalPriceMarkup += this.priceMarkup;
      }
       if(this.hinge){
        sub['hinge'] = this.hinge;
        this.hingesAmount += this.amount * this.HINGES_AMOUNT[this.hinge];
      }
      this.totalPrice += this.priceCalc;
      this.totalArea += this.totalAreaOne;
      this.totalArea = +this.totalArea.toFixed(2);
      this.totalAmount += +this.amount;

      this.lessThan1SqM = this.totalArea < 1;

      if(this.lessThan1SqM){
        this.totalPriceLessThan1sqM = Math.ceil(this.totalPrice*this.LESS_THAN_1_MARKUP);
        this.totalPriceLessThan1sqMMarkup = Math.ceil(this.totalPriceMarkup*this.LESS_THAN_1_MARKUP);
      }
      this.addedOrder = 'Позиция добавлена';
      this.doneOrders.push(sub);

    }else if(this.typeF === 'kromka'){
      let sub: SubOrder = {
        typeF: "LUX кромка",
        thickness: this.thickness,
        collect: this.collectLUX,
        ...(this.kromkaDesign ? {decor: '----'}:{decor: this.decorLUX}),
        height: this.height,
        width: this.width,
        amount: this.amount,
        area: this.area,
        totalArea: this.totalAreaOne,
        price: this.pricePerMeter,
        priceCalc: this.priceCalc,
      };
      if (this.statusCheck) {
        sub['priceMarkup'] = this.priceMarkup;
        this.totalPriceMarkup += this.priceMarkup;
      }
      if(this.kromkaDesign){
        sub['kromkaDesign'] = this.kromkaDesign;
        sub['collect'] = 'Дизайн кромка';
      }
      this.totalPrice += this.priceCalc;
      
      this.addedOrder = 'Позиция добавлена';
      this.doneOrders.push(sub);
    }
  }

  private confirmAndCreateOrder(statusStr: string){
    if(statusStr == 'Сохранён'){
      if(!confirm("Сохранить заказ?")){
        return;
      }
    }else{
      if(!confirm("После отправления заказа на оформление менеджеру вам будет направлен счёт с индивидуальной скидкой. Оптовая сумма будет отображена в журнале заказов.\nПОСЛЕ ОПЛАТЫ СЧЁТА, КОРРЕКТИРОВКА ИЛИ ИЗМЕНЕНИЯ ЗАКАЗА НЕВОЗМОЖНЫ")){
        return;
      }
    }
    if(this.lessThan1SqM){
      this.totalPrice = this.totalPriceLessThan1sqM;
    }
    let orderObj: Order = {
      order: this.doneOrders,
      totalArea: this.totalArea,
      commentsForClient: this.messageForClient,
      commentsForCompany: this.messageForCompany,
      paymentPlanForm: this.paymentPlanForm,
      paymentPlanDate: this.paymentDate,
      phone: this.phone,
      person: this.person,
      name: this.name,
      phoneChildren: this.parentID,
      totalPrice: this.totalPrice,
      totalPriceCalc: this.totalPrice,
      kind: 'facade',
      status: statusStr,
      ...(this.phoneForWho ? {createdResponsible: this.phoneForWho} : {}) //На этом этапе поменяны местами логика, на бэке норм встаёт
    };
    if(this.childrenCheck){
      orderObj['childrenCheck'] = true;
    }
    if (this.statusCheck) {
      orderObj['totalPriceMarkup'] = this.lessThan1SqM ? this.totalPriceLessThan1sqMMarkup : this.totalPriceMarkup;
    } else {
      orderObj['totalPriceMarkup'] = this.lessThan1SqM ? this.totalPriceLessThan1sqM : this.totalPrice;
    }
    this.loadingOpt = true;
    this.apiService.createOrder(orderObj).subscribe((data: any) => {
      if ('message' in data) {
        if (
          !data.message.includes('создан') &&
          !data.message.includes('sent')
        ) {
          this.errorMessage = JSON.stringify(data.message);
          this.loadingOpt = false;
          return;
        }
      }
      this.authService.setCookieCreatedOrder(data);
      this.authService.removeOrderGuardCheck();
      this.router.navigate(['list']);
    });
  }

  checkIntegra(opt: string): boolean{
    if(this.collectPVC == 'Integra' && !this.options.decorPVC.integraDep.entries[opt][0]){
      return false;
    }
    return true;
  }

  checkStar(opt: string): boolean{
    if(!this.options.decorPVC.starDep.entries[opt].includes(this.star) && this.collectPVC != 'Integra'){
      return false;
    }
    return true;
  }



  removeSuborder(i: number) {
    this.addedOrder = '';
    this.errorMessage = '';
    if (this.statusCheck) {
      //@ts-ignore
      this.totalPriceMarkup -= this.doneOrders[i].priceMarkup;
    }
    if(this.doneOrders[i].hinge){
      //@ts-ignore
      this.hingesAmount -= this.doneOrders[i].amount * this.HINGES_AMOUNT[this.doneOrders[i].hinge];
    }
    //@ts-ignore
    this.totalPrice -= this.doneOrders[i].priceCalc;
    this.totalArea -= this.doneOrders[i].totalArea;
    this.totalArea = +this.totalArea.toFixed(2);
    this.totalAmount -= +this.doneOrders[i].amount;
    this.lessThan1SqM = this.doneOrders[i].typeF == 'PVC' && this.totalArea < 1;
    if(this.lessThan1SqM){
      //@ts-ignore
      this.totalPriceLessThan1sqM = Math.ceil(this.totalPrice*this.LESS_THAN_1_MARKUP);
      //@ts-ignore
      this.totalPriceLessThan1sqMMarkup = Math.ceil(this.totalPriceMarkup*this.LESS_THAN_1_MARKUP);
    }
    this.doneOrders.splice(i, 1);
  }

  canCalcPrice(): boolean{
    if(!this.typeF){
      this.errorMessage = 'Не выбран тип фасада';
      return false;
    }
    if(this.typeF === 'kromka'){
      if(!this.collectLUX){
        this.errorMessage = 'Не указана коллекция';
        return false;    
      }
      if(this.collectLUX === 'design'){
        if(!this.kromkaDesign){
          this.errorMessage = 'Не указан дизайн';
          return false;    
        }
        this.decorLUX = "";
      }else{
        if(!this.decorLUX){
          this.errorMessage = 'Не указан декор';
          return false;    
        }
      }
      this.height = 0;
      this.width = 0;
      this.thickness = '1';
      return true;
    }
    if(this.typeF === 'Fenix3D'){
      if(!this.collectLUX){
        this.errorMessage = 'Не указана коллекция';
        return false;    
      }
      if(!this.thickness){
        this.errorMessage = 'Не указан декор';
        return false;    
      }
      if(!this.decorLUX){
        this.errorMessage = 'Не указан декор';
        return false;    
      }
      return true;
    }
    if(this.typeF === 'PVC'){
      if(!this.decorPVC){
        this.errorMessage = 'Не указана плёнка';
        return false;
      }
      if(!this.collectPVC){
        this.errorMessage = 'Не указана коллекция';
        return false;
      }
      if(!this.milling){
        this.errorMessage = 'Не указана фрезеровка';
        return false;
      }
    }
    if(this.typeF === 'LUX'){
      if(!this.kromka){
        this.errorMessage = 'Не указана кромка';
        return false;
      }
      if(!this.collectLUX){
        this.errorMessage = 'Не указана коллекция';
        return false;
      }
    }
    if(!this.thickness){
      this.errorMessage = 'Не указана толщина';
      return false;
    }
    if(this.typeF === 'LUX'){
      if(this.kromka == 'Дизайн кромка' && !this.kromkaDesign){
        this.errorMessage = 'Не указан дизайн кромки';
        return false;
      }
    }
    if(this.amount <= 0 || this.width <= 0 || this.height <= 0){
      this.errorMessage = 'Не указаны размеры или они отрицательны';
      return false;
    }

    let tmp, tmpEntry;
    if(this.typeF === 'LUX' || this.typeF === 'Fenix3D'){
      tmp = this.options.collectLUX; //Просто чтобы было компактнее
      tmpEntry = this.collectLUX;
    }else{
      tmp = this.options.milling;
      tmpEntry = this.milling;
    }

    if (this.width < parseInt(tmp.minWidthDep.entries[tmpEntry],10)){
      this.errorMessage = `Ширина фасада меньше допустимой
        (Минимум ${tmp.minWidthDep.entries[tmpEntry]})`;
        return false;
    }
    if (this.height < parseInt(tmp.minHeightDep.entries[tmpEntry],10)){
      this.errorMessage = `Высота фасада меньше допустимой
        (Минимум ${tmp.minHeightDep.entries[tmpEntry]})`;
        return false;
    }
    if (this.width > parseInt(tmp.maxWidthDep.entries[tmpEntry],10)){
      this.errorMessage = `Ширина фасада больше допустимой
        (Максимум ${tmp.maxWidthDep.entries[tmpEntry]})`;
        return false;
    }
    if (this.height > parseInt(tmp.maxHeightDep.entries[tmpEntry],10)){
      this.errorMessage = `Высота фасада больше допустимой
        (Максимум ${tmp.maxHeightDep.entries[tmpEntry]})`;
        return false;
    }

    if(this.typeF == 'PVC'){
      //@ts-ignore
      if(parseInt(this.price.pricePVC.thickness[this.thickness].collect[this.collectPVC].group[this.options.decorPVC. groupDep.entries[this.decorPVC]]) <= 0){
        this.errorMessage = "Невозможно сделать данный фасад";
        return false;
      }
      if(this.texture == 'Горизонтальная'){
        if (this.height > 1200){
          this.errorMessage = `Высота фасада больше допустимой
            (Максимум 1200 из-за горизонтальной текстуры)`;
            return false;
        }
      }
    }

    if(this.typeF == 'LUX' || this.typeF === 'Fenix3D'){
      //@ts-ignore
      //@ts-ignore
      if(parseInt(this.price.priceLUX.thickness[this.thickness].collect[this.collectLUX].kromka[this.kromka]) <= 0){
        this.errorMessage = "Невозможно сделать данный фасад";
        return false;
      }
      if(this.collectLUX == 'Woodlux'){
        if(this.texture == 'Горизонтальная'){
          if (this.height > 1200){
            this.errorMessage = `Высота фасада больше допустимой
              (Максимум 1200 из-за горизонтальной текстуры)`;
              return false;
          }
        }
      }
    }

    return true;
  }

  calcArea() {
    this.area = Math.ceil((+this.width) * (+this.height)) / 1000000;
    this.totalAreaOne = Math.ceil((+this.width) * (+this.height)*(+this.amount)) / 1000000;
    this.area = +this.area.toFixed(2);
    this.totalAreaOne = +this.totalAreaOne.toFixed(2);
  }

  calcPrice() {
    this.priceCalc = 0;
    if(!this.canCalcPrice()){
      return;
    }

    this.addedOrder = '';
    this.errorMessage = '';
    
    if(this.typeF === 'kromka'){
      this.pricePerMeter = this.KROMKA_PRICE[this.collectLUX];
      this.priceCalc = Math.ceil(this.KROMKA_PRICE[this.collectLUX]*this.amount);
      let markupLocal = 1 + (this.markupConst/100);
      this.priceMarkup =  Math.ceil(this.priceCalc * markupLocal);
      this.totalArea = 0;
      this.totalAreaOne = 0;
      this.area = 0;
      return;
    }


    this.calcArea();

    if(this.typeF === 'LUX' || this.typeF === 'Fenix3D'){
      //@ts-ignore
      this.pricePerMeter = parseInt(this.price.priceLUX.thickness[this.thickness].collect[this.collectLUX].kromka[this.kromka]);
      // const basePrice = Math.ceil((
      //   //@ts-ignore
      //     parseInt(this.price.priceLUX.thickness[this.thickness].collect[this.collectLUX].kromka[this.kromka]) *
      //     this.area * this.amount) / 10) * 10;
      // this.priceCalc = basePrice;

      if(this.area < 0.2){
        // this.priceCalc += basePrice * this.LESS_THAN_02_MARKUP;
        this.pricePerMeter *= this.LESS_THAN_02_MARKUP;
      }

      if(this.width < 100 || this.height < 100){
        this.pricePerMeter *= this.LESS_THAN_100_MARKUP;
      }

      // this.pricePerMeter = Math.ceil(this.pricePerMeter);
      this.priceCalc = Math.ceil(this.totalAreaOne*this.pricePerMeter);
      // this.priceCalc = Math.ceil(this.priceCalc);
    }
    if(this.typeF === 'PVC'){
      //@ts-ignore
      this.pricePerMeter = parseInt(this.price.pricePVC.thickness[this.thickness].collect[this.collectPVC].group[this.options.decorPVC.groupDep.entries[this.decorPVC]]);
      const basePrice = this.pricePerMeter;
      let plusPrice = 0;
      // const basePrice = Math.ceil((
      //   //@ts-ignore
      //   parseInt(this.price.pricePVC.thickness[this.thickness].collect[this.collectPVC].group[this.options.decorPVC.groupDep.entries[this.decorPVC]]) *
      //     this.area * this.amount) / 10) * 10;
      // this.priceCalc = basePrice;

      if(this.star){
        if(this.options.star.priceMultDep.entries[this.star][0]){
          // this.priceCalc += basePrice * this.options.star.priceDep.entries[this.star][0] - this.priceCalc;
          this.pricePerMeter += basePrice * this.options.star.priceDep.entries[this.star][0] - basePrice;
        }else{
          // this.priceCalc += 1*this.options.star.priceDep.entries[this.star][0];
          plusPrice += 1*this.options.star.priceDep.entries[this.star][0];
        }
      }
      if(this.ispol){
        if(this.options.ispol.priceMultDep.entries[this.ispol][0]){
          // this.priceCalc += basePrice * this.options.ispol.priceDep.entries[this.ispol][0] - this.priceCalc;
          this.pricePerMeter += basePrice * this.options.ispol.priceDep.entries[this.ispol][0] - basePrice;
        }else{
          plusPrice += 1*this.options.ispol.priceDep.entries[this.ispol][0];
          // this.priceCalc += 1*this.options.ispol.priceDep.entries[this.ispol][0];
        }
      }
      if(this.polish){
        if(this.options.polish.priceMultDep.entries[this.polish][0]){
          this.pricePerMeter += basePrice * this.options.polish.priceDep.entries[this.polish][0] - basePrice;
          // this.priceCalc += basePrice * this.options.polish.priceDep.entries[this.polish][0] - this.priceCalc;
        }else{
          plusPrice += 1*this.options.polish.priceDep.entries[this.polish][0];
          // this.priceCalc += 1*this.options.polish.priceDep.entries[this.polish][0];
        }
      }
      if(this.width < 100 || this.height < 100){
        this.pricePerMeter += basePrice * this.LESS_THAN_100_MARKUP - basePrice;
        // this.pricePerMeter *= this.LESS_THAN_100_MARKUP;
      }
      // this.pricePerMeter = Math.ceil(this.pricePerMeter);
      this.priceCalc = Math.ceil((this.totalAreaOne*this.pricePerMeter+plusPrice));
    }
    if(this.hinge){
      this.priceCalc += this.HINGES_AMOUNT[this.hinge]*this.HINGES_PRICE*this.amount;
      this.priceCalc = Math.ceil(this.priceCalc);
    }
    let markupLocal = 1 + (this.markupConst/100);
    this.priceMarkup =  Math.ceil(this.priceCalc * markupLocal);
  }

  doMarkup(){
    // this.errorMessage = 'IN progress';
    this.markupConst = this.markup;
    let markupPrice = 0;
    let markupLocal = 1 + (this.markupConst/100);
    for (const subOrder of this.doneOrders) {
      if(subOrder.priceCalc){
        subOrder.priceMarkup = Math.ceil(subOrder.priceCalc * markupLocal);
        markupPrice += subOrder.priceMarkup;
      }
    }
    if(this.typeF && this.typeF == 'PVC') {
      this.totalPriceLessThan1sqMMarkup = Math.ceil(markupPrice*this.LESS_THAN_1_MARKUP);
    }
    this.totalPriceMarkup = markupPrice;
  }

  getPriceLUX(kromka: string){
    // console.log(this.typeF);
    //@ts-ignore
    if (this.price.priceLUX.thickness[this.thickness]['collect'][this.collectLUX]["kromka"][kromka] > 0) {
      return true;
    } else {
      return false;
    }
  }

  resetOptions(num: number) {
    this.addedOrder = '';
    this.errorMessage = '';
    this.optionsCount = num;
    if (this.typeF === 'LUX') {
      if (num == 3){
        this.kromkaCheck = true;
      }
      if(num < 6){
        this.texture = '';
        this.hinge = '';
      }
      if (num < 5) {
        this.decorLUX = '';
        this.kromkaDesign = '';
        if(this.options.decorLUX.catalogDep.entries[this.decorLUX] == 'wood'){
          this.texture = 'Вертикальная';
        }
      }
      if (num == 4){
        this.filteredOptionsLUX = this.options.decorLUX.entries;
      }
      if (num < 4) {
        this.kromka = '';
      }
      if (num < 3) {
        // this.options.decorPVC.catalogDep.entries[this.decorPVC] === 'wood'
        this.thickness = '';
      }
      if (num < 2) {
        this.collectLUX = '';
      }
    } else if (this.typeF === 'PVC'){
      if(num == 8){
        this.starCheck = true;
        if(this.collectPVC == 'Integra' || this.collectPVC == 'Velvet'){
          this.resetOptions(10);
          return;
        }
      }
      if (num == 6){
        this.filteredOptionsPVC = this.options.decorPVC.entries;
      }
      if(num == 4){
        this.frezaCheck = true;
      }
      if(num < 10){
        this.polish = '';
        this.hinge = '';
      }
      if(num < 9){
        this.star = '';
      }
      if(num < 8){
        this.texture = '';
      }
      if(num < 7){
        this.decorPVC = '';
      }
      if(num < 6){
        this.ispol = '';
      }
      if (num < 5) {
        this.milling = '';
      }
      if(num < 4){
        this.freza = '';
      }
      if (num < 3) {
        this.thickness = '';
      }
      if (num < 2) {
        this.collectPVC = '';
      }
    }else if(this.typeF === 'Fenix3D'){
      if(num < 6){
        this.hinge = '';
      }
      if (num < 5) {
        this.decorLUX = '';
      }
      if (num == 4){
        this.filteredOptionsLUX = this.options.decorLUX.entries;
      }
      if(num == 3){
        this.resetOptions(4);
      }
      if (num < 4) {
        this.kromka = 'Кромка в цвет';
      }
      if (num < 3) {
        // this.options.decorPVC.catalogDep.entries[this.decorPVC] === 'wood'
        this.thickness = '';
      }
      if (num < 2) {
        this.collectLUX = '';
      }
    }
    else{
      if(num < 2){
        this.collectLUX = '';
      }
      if(num < 3){
        this.decorLUX = '';
        this.kromkaDesign = '';
      }
    }
  }
  ceil(num: number){
    return Math.ceil(num);
  }
  resetMessages(){
    this.addedOrder = '';
    this.errorMessage = '';
    for (const key of Object.keys(this.MAP_ERROR)) {
      this.MAP_ERROR[key];
    }
  }
  copy(index: number){ //При наведении писать что копируем
    const order = this.doneOrders[index];
    this.resetOptions(1);
    if(order.typeF === 'PVC'){
      this.typeF = 'PVC';
      this.collectPVC = order.collect;
      this.thickness = order.thickness;
      if(order.freza){
        this.freza = order.freza;
      }
      if(order.milling){
        this.milling = order.milling;
      }
      if(order.ispol){
        this.ispol = order.ispol;
      }
      this.decorPVC = order.decor;
      if(order.texture){
        this.texture = order.texture as '' | 'Вертикальная' | 'Горизонтальная';
      }
      if(order.star){
        this.star = order.star;
      }
      if(order.polish){
        this.polish = order.polish;
      }
    }else if(order.typeF === 'LUX'){
      this.typeF = 'LUX';
      this.collectLUX = order.collect;
      this.thickness = order.thickness;
      if(order.kromka){
        this.kromka = order.kromka;
      }
      if(order.kromkaDesign){
        this.kromkaDesign = order.kromkaDesign;
      }
      this.decorLUX = order.decor;
      if(order.texture){
        this.texture = order.texture as '' | 'Вертикальная' | 'Горизонтальная';
      }
    }else if(order.typeF === 'LUX кромка'){
      this.typeF = 'kromka';
      if(order.collect === 'Дизайн кромка'){
        this.collectLUX = 'design';
        this.kromkaDesign = this.kromkaDesign;
      }else{
        this.collectLUX = order.collect;
        this.decorLUX = order.decor;
      }
    }else{
      this.errorMessage = 'Невозоможно скопировать данный тип';
    }
    if(order.hinge){
      this.hinge = order.hinge;
    }
    this.height = order.height;
    this.width = order.width;
    this.amount = order.amount;
    this.resetOptions(20);
  }
  private validatePhone(phone: string) {
    const regex = /^(8)[0-9]{3}[0-9]{7}$/;
    if(regex.test(phone)){
      return true;
    }
    else{
      return false;
    }
  }
}
