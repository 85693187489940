import { Component } from '@angular/core';

@Component({
  selector: 'app-personal-data',
  standalone: true,
  imports: [],
  templateUrl: './personal-data.component.html',
  styleUrl: './personal-data.component.scss'
})
export class PersonalDataComponent {

}
