import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date',
  standalone: true
})
export class DatePipe implements PipeTransform {

  transform(value: string | undefined, ...args: unknown[]): unknown {
    if(!value){
      return value;
    }
    return value.replaceAll('/', '.');
  }

}