import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { jwtDecode } from 'jwt-decode';
import { NgClass } from '@angular/common';
import { HandsetService } from '../../services/handset.service';
import { AsyncPipe } from '@angular/common';
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, NgClass, AsyncPipe, NgClass],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  account = new FormGroup({
    phone: new FormControl(''),
    password: new FormControl(''),
  });
  errorMessage: string = '';
  constructor(
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService,
    public handset: HandsetService
  ) {}
  loading: boolean = false;
  onSubmit(): void {
    let phone = this.account.value.phone;
    const password = this.account.value.password;
    if (!phone || !password) {
      this.errorMessage = 'Все поля обязательны.';
      return;
    }
    this.errorMessage = '';
    phone = phone.replaceAll('+7', '8');
    this.loading = true;
    //encode password?
    this.apiService.login(parseInt(phone, 10), password).subscribe({
      next: (data) => {
        this.authService.setCookie(data);
        this.loading = false;
      },
      error: (err) => {
        this.errorMessage = err.error.message;
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
        let temp = this.authService.getCookie();
        if (temp) {
          this.authService.getCookie().subscribe((data)=>{
            if(!data.verified){
              this.errorMessage = "Аккаунт не прошёл проверку. Подождите, или обратитесь в поддержку.";
              return;
            }
            localStorage.setItem('session', data.role); // ЧТО ЭТО ТАКОЕ ???? UPD: ПОХУЙ ДЛЯ АУТЕНТИФИКАЦИИ ЗАЕБИСЬ
            

            if(localStorage.getItem('register')){
              this.router.navigate(['about']).finally(() => {
                localStorage.removeItem('register');
                location.reload();
              });
            } else if(localStorage.getItem('url')){
              this.router.navigate([localStorage.getItem('url')]).finally(() => {
                localStorage.removeItem('url');
                location.reload();
              });
            } else {
              this.router.navigate(['/list']).finally(() => {
                location.reload();
              });
            }    
          });
        }
      },
    });
  }
}
