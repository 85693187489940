import { Component } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { AsyncPipe } from '@angular/common';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import {
  BreakpointObserver,
  Breakpoints,
  LayoutModule,
} from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatNavList } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';

// import { Observable, map, shareReplay } from 'rxjs';

import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HandsetService } from '../services/handset.service';
@Component({
  selector: 'app-shell-login',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatToolbarModule,
    MatNavList,
    MatIconModule,
    NgIf,
    LayoutModule,
    RouterModule,
    AsyncPipe,
    MatButtonModule,
    MatListModule,
    NgClass
  ],
  templateUrl: './shell-login.component.html',
  styleUrl: './shell-login.component.scss',
})
export class ShellLoginComponent {
  // isHandset$: Observable<boolean> = this.breakpointObserver
  //   .observe([Breakpoints.Handset])
  //   .pipe(
  //     map((result) => result.matches),
  //     shareReplay()
  //   );
  constructor(
    // private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    public router: Router,
    public handset: HandsetService
  ) {
    console.log('Login');
    this.isAuthenticated = authService.isAuthenticated();
    // console.log(router.url);
    handset.isHandset$.subscribe((data) => {
      if (data){
        localStorage.setItem("mobile", "true");
      }
    });
  }
  isAuthenticated: boolean = false;
}
